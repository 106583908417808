import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130')
];

export const server_loads = [];

export const dictionary = {
		"/": [36],
		"/(console)/account": [40,[3,5],[4]],
		"/(console)/account/activity": [41,[3,5],[4]],
		"/(console)/account/organizations": [42,[3,5],[4]],
		"/(console)/account/payments": [43,[3,5],[4]],
		"/(console)/account/sessions": [44,[3,5],[4]],
		"/(console)/apply-credit": [45,[3],[4]],
		"/(public)/auth/magic-url": [123,[33]],
		"/(public)/auth/oauth2/failure": [124,[33]],
		"/(public)/auth/oauth2/success": [125,[33]],
		"/(public)/card": [126,[34]],
		"/(public)/card/[uid]": [127,[34]],
		"/(console)/create-organization": [46,[3],[4]],
		"/(authenticated)/git/authorize-contributor": [37,[2]],
		"/(public)/hackathon": [128,[35]],
		"/(public)/invite": [129],
		"/(public)/(guest)/login": [120,[31]],
		"/(console)/(redirects)/members": [39,[3],[4]],
		"/(authenticated)/mfa": [38],
		"/(console)/onboarding": [47,[3,6],[4]],
		"/(console)/organization-[organization]": [48,[3,7],[4]],
		"/(console)/organization-[organization]/billing": [49,[3,7],[4]],
		"/(console)/organization-[organization]/change-plan": [50,[3,7],[4]],
		"/(console)/organization-[organization]/members": [51,[3,7],[4]],
		"/(console)/organization-[organization]/settings": [52,[3,7],[4]],
		"/(console)/organization-[organization]/usage/[[invoice]]": [53,[3,7],[4]],
		"/(console)/project-[project]": [54,[3,8],[4,9]],
		"/(console)/project-[project]/auth": [55,[3,8,10],[4,9]],
		"/(console)/project-[project]/auth/security": [56,[3,8,10],[4,9]],
		"/(console)/project-[project]/auth/settings": [57,[3,8,10],[4,9]],
		"/(console)/project-[project]/auth/teams": [58,[3,8,10],[4,9]],
		"/(console)/project-[project]/auth/teams/team-[team]": [59,[3,8,10,11],[4,9]],
		"/(console)/project-[project]/auth/teams/team-[team]/activity": [60,[3,8,10,11],[4,9]],
		"/(console)/project-[project]/auth/teams/team-[team]/members": [61,[3,8,10,11],[4,9]],
		"/(console)/project-[project]/auth/templates": [62,[3,8,10],[4,9]],
		"/(console)/project-[project]/auth/usage/[[period]]": [63,[3,8,10],[4,9]],
		"/(console)/project-[project]/auth/user-[user]": [64,[3,8,10,12],[4,9]],
		"/(console)/project-[project]/auth/user-[user]/activity": [65,[3,8,10,12],[4,9]],
		"/(console)/project-[project]/auth/user-[user]/identities": [66,[3,8,10,12],[4,9]],
		"/(console)/project-[project]/auth/user-[user]/memberships": [67,[3,8,10,12],[4,9]],
		"/(console)/project-[project]/auth/user-[user]/sessions": [68,[3,8,10,12],[4,9]],
		"/(console)/project-[project]/auth/user-[user]/targets": [69,[3,8,10,12],[4,9]],
		"/(console)/project-[project]/databases": [70,[3,8,13],[4,9]],
		"/(console)/project-[project]/databases/database-[database]": [71,[3,8,13,14],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/collection-[collection]": [72,[3,8,13,14,15],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/collection-[collection]/activity": [73,[3,8,13,14,15],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/collection-[collection]/attributes": [74,[3,8,13,14,15],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/collection-[collection]/document-[document]": [75,[3,8,13,14,15,16],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/collection-[collection]/document-[document]/activity": [76,[3,8,13,14,15,16],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/collection-[collection]/document-[document]/data": [77,[3,8,13,14,15,16],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/collection-[collection]/indexes": [78,[3,8,13,14,15],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/collection-[collection]/settings": [79,[3,8,13,14,15],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/collection-[collection]/usage/[[period]]": [80,[3,8,13,14,15],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/settings": [81,[3,8,13,14],[4,9]],
		"/(console)/project-[project]/databases/database-[database]/usage/[[period]]": [82,[3,8,13,14],[4,9]],
		"/(console)/project-[project]/databases/usage/[[period]]": [83,[3,8,13],[4,9]],
		"/(console)/project-[project]/functions": [84,[3,8,17],[4,9]],
		"/(console)/project-[project]/functions/function-[function]": [85,[3,8,17,18],[4,9]],
		"/(console)/project-[project]/functions/function-[function]/deployment-[deployment]": [86,[3,8,17,18,19],[4,9]],
		"/(console)/project-[project]/functions/function-[function]/domains": [87,[3,8,17,18],[4,9]],
		"/(console)/project-[project]/functions/function-[function]/executions": [88,[3,8,17,18],[4,9]],
		"/(console)/project-[project]/functions/function-[function]/executions/execute-function": [89,[3,8,17,18],[4,9]],
		"/(console)/project-[project]/functions/function-[function]/settings": [90,[3,8,17,18],[4,9]],
		"/(console)/project-[project]/functions/function-[function]/usage/[[period]]": [91,[3,8,17,18],[4,9]],
		"/(console)/project-[project]/functions/templates": [92,[3,8,17],[4,9]],
		"/(console)/project-[project]/functions/templates/template-[template]": [93,[3,8,17,20],[4,9]],
		"/(console)/project-[project]/messaging": [94,[3,8,21],[4,9]],
		"/(console)/project-[project]/messaging/message-[message]": [95,[3,8,21,22],[4,9]],
		"/(console)/project-[project]/messaging/providers": [96,[3,8,21],[4,9]],
		"/(console)/project-[project]/messaging/providers/provider-[provider]": [97,[3,8,21,23],[4,9]],
		"/(console)/project-[project]/messaging/topics": [98,[3,8,21],[4,9]],
		"/(console)/project-[project]/messaging/topics/topic-[topic]": [99,[3,8,21,24],[4,9]],
		"/(console)/project-[project]/messaging/topics/topic-[topic]/activity": [100,[3,8,21,24],[4,9]],
		"/(console)/project-[project]/messaging/topics/topic-[topic]/settings": [101,[3,8,21,24],[4,9]],
		"/(console)/project-[project]/overview": [102,[3,8,25],[4,9]],
		"/(console)/project-[project]/overview/keys": [103,[3,8,25],[4,9]],
		"/(console)/project-[project]/overview/keys/[key]": [104,[3,8],[4,9]],
		"/(console)/project-[project]/overview/platforms": [105,[3,8,25],[4,9]],
		"/(console)/project-[project]/overview/platforms/[platform]": [106,[3,8],[4,9]],
		"/(console)/project-[project]/settings": [107,[3,8,26],[4,9]],
		"/(console)/project-[project]/settings/domains": [108,[3,8,26],[4,9]],
		"/(console)/project-[project]/settings/migrations": [109,[3,8,26],[4,9]],
		"/(console)/project-[project]/settings/smtp": [110,[3,8,26],[4,9]],
		"/(console)/project-[project]/settings/usage/[[invoice]]": [111,[3,8,26],[4,9]],
		"/(console)/project-[project]/settings/webhooks": [112,[3,8,26],[4,9]],
		"/(console)/project-[project]/settings/webhooks/[webhook]": [113,[3,8,26,27],[4,9]],
		"/(console)/project-[project]/storage": [114,[3,8,28],[4,9]],
		"/(console)/project-[project]/storage/bucket-[bucket]": [115,[3,8,28,29],[4,9]],
		"/(console)/project-[project]/storage/bucket-[bucket]/file-[file]": [116,[3,8,28,29,30],[4,9]],
		"/(console)/project-[project]/storage/bucket-[bucket]/settings": [117,[3,8,28,29],[4,9]],
		"/(console)/project-[project]/storage/bucket-[bucket]/usage/[[period]]": [118,[3,8,28,29],[4,9]],
		"/(console)/project-[project]/storage/usage/[[period]]": [119,[3,8,28],[4,9]],
		"/(public)/recover": [130],
		"/(public)/(guest)/register": [121,[31]],
		"/(public)/(guest)/register/invite/[slug]": [122,[31,32]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';